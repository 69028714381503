@import 'fonts.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'home.scss';

@layer base {
    html {
      font-family: Raleway, system-ui, sans-serif;
    }

  }
  .ul-list li{
       @apply mb-[4px];
    }

    .ul-list li::before {
        @apply text-amber-500;
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
