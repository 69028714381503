@font-face {
    font-family: Raleway;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    src: url('../../public/fonts/rawline-400.ttf') format("truetype");
}
@font-face {
    font-family: RalewayBold;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    src: url('../../public/fonts/rawline-600.ttf') format("truetype");
}
@font-face {
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    src: url('../../public/fonts/Roboto-Regular.ttf') format("truetype");
}

